import { Work } from "@biblioteksentralen/cordata";
import { filterManifestationsOnHoldings } from "./manifestations";

export const filterWorkDataOnHoldings =
  <WorkType extends Partial<Work>>(libraryNumbers: string[] | null) =>
  (work: WorkType): WorkType => {
    if (!libraryNumbers) return work;

    const expressions = work.expressions
      ?.map(({ manifestations = [], ...expression }) => ({
        ...expression,
        manifestations: filterManifestationsOnHoldings(manifestations, libraryNumbers),
      }))
      .filter(({ manifestations = [] }) => manifestations.length > 0);
    return { ...work, expressions };
  };
