import { sift } from "radash";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { useCommonData } from "../../components/layout/CommonDataProvider";
import { useRediaPlatformContext } from "../../rediaPlatform/RediaPlatformProvider";
import { libraryNumberToIsilCode } from "../cordata/items";
import { BranchInformation } from "@libry-content/redia-platform";

type Identifier = BranchInformation["externals"]["identifiers"][number];

const hasLibraryNumber =
  (libraryNumber: string | undefined) =>
  ({ type, value }: Identifier) =>
    !!libraryNumber && type === "isil" && value === libraryNumberToIsilCode(libraryNumber);

export const useLibrarySystemBranches = () => {
  const { rediaPlatform, isSessionReady, user } = useRediaPlatformContext();
  const { site } = useCommonData();
  const isReady = !!site && isSessionReady && !!rediaPlatform && !!user;

  const { data, error, isLoading } = useSWR(
    isReady ? `/${site._id}/branches` : null,
    () => rediaPlatform?.getBranches() ?? null,
    { onError: (err) => console.error(err) }
  );

  const branches = useMemo(() => sift(Object.values(data ?? {})), [data]);

  const isilCodeFromBranchCode = useCallback(
    (branchCode: string | undefined) =>
      branches.find(({ code }) => code === branchCode)?.externals.identifiers.find(({ type }) => type === "isil")
        ?.value,
    [branches]
  );

  const branchCodeFromLibraryNumber = useCallback(
    (libraryNumber: string | undefined) =>
      branches.find(({ externals }) => externals.identifiers.find(hasLibraryNumber(libraryNumber)))?.code,
    [branches]
  );

  return {
    branches,
    isilCodeFromBranchCode: !isReady || isLoading ? undefined : isilCodeFromBranchCode,
    branchCodeFromLibraryNumber: !isReady || isLoading ? undefined : branchCodeFromLibraryNumber,
    error,
    isLoading,
  };
};
