import {
  Language as CordataLanguage,
  DocumentType,
  Expression,
  Holdings,
  Manifestation,
  Title,
  Work,
} from "@biblioteksentralen/cordata";
import { isNonNil } from "@libry-content/common";
import { min, unique } from "radash";
import { useRediaPlatformContext } from "../../rediaPlatform/RediaPlatformProvider";
import { sortByMultiple } from "../../utils/sort";
import { useLibrarySystemBranches } from "../hooks/useLibrarySystemBranches";
import { CollectionExpressionEntryWithWork, collectionExpressionEntryHasWork } from "./collections";
import { isValidCoverImage } from "./coverImage";
import { holdingCanBeReserved, holdingIsAvailable, isHoldingRepresentedInIsilNumbers } from "./items";
import { CordataLanguageCode, createLanguageCodeOrder, getSortedLanguageCodeIndex, handleLanguages } from "./languages";
import { havePartTitles, haveSamePartTitlesSctructure, isbdFormattedTitle } from "./titles";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">): DocumentType[] =>
  unique(
    expressions.flatMap(({ manifestations }) =>
      manifestations.flatMap(({ documentType }) => documentType).filter(isNonNil)
    ),
    ({ format }) => format
  );

export type DecoratedManifestation = Manifestation & {
  languages: Expression["languages"];
  collections: CollectionExpressionEntryWithWork[];
};

const getDecoratedWorkManifestations = ({ expressions }: Work): DecoratedManifestation[] =>
  unique(
    expressions.flatMap(({ manifestations, languages, collections = [] }) =>
      manifestations.map((manifestation) => ({
        ...manifestation,
        languages: handleLanguages()(languages),
        collections: unique(
          collections.filter(collectionExpressionEntryHasWork),
          ({ collection }) => collection?.workExpressed?.id
        ),
      }))
    ),
    ({ id }) => id
  );

// TODO: Indicate these in dataplattform -> cordata types, similarly to languages?
const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getPreferredFormatOrderIndex = (
  format: string | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

type Representation = { representativeLanguageCode?: CordataLanguageCode; representativeFormat?: string };

export type WorkToBeRepresented = Work & Representation;

export type SortableManifestation = Pick<
  DecoratedManifestation,
  "id" | "languages" | "documentType" | "title" | "publicationYear" | "coverImage" | "holdings"
>;

export const getSortableManifestation =
  (languages: CordataLanguage[], preferredLanguageCodeOrder?: readonly CordataLanguageCode[]) =>
  (manifestation: Manifestation): SortableManifestation => ({
    ...manifestation,
    languages: handleLanguages(preferredLanguageCodeOrder)(languages),
  });

export type ManifestationSorter<T extends SortableManifestation> = (items: T[]) => T[];

const geSortedIsilCodeIndex = (preferredIsilCode: string | undefined, holdings: Holdings[]) =>
  preferredIsilCode ? min(holdings.map(({ isilCode }) => (isilCode === preferredIsilCode ? 1 : 2))) ?? 1 : 1;

export const sortManifestationsByRelevance =
  <T extends SortableManifestation>(
    workTitle: Title,
    preferredLanguageCodeOrder: readonly CordataLanguageCode[],
    preferredFormatOrder: string[],
    preferredIsilCode?: string | undefined
  ): ManifestationSorter<T> =>
  (items) =>
    sortByMultiple(
      items,
      ({ languages }) => getSortedLanguageCodeIndex(languages[0], preferredLanguageCodeOrder),
      ({ documentType }) => getPreferredFormatOrderIndex(documentType?.format, preferredFormatOrder),
      ({ title }) => (haveSamePartTitlesSctructure(workTitle, title) ? 1 : 2),
      ({ holdings = [] }) => (holdings.some(holdingCanBeReserved) ? 1 : 2),
      ({ holdings = [] }) => geSortedIsilCodeIndex(preferredIsilCode, holdings),
      ({ holdings = [] }) => (holdings.some(holdingIsAvailable) ? 1 : 2),
      ({ publicationYear }) => (isNaN(Number(publicationYear)) ? 0 : -Number(publicationYear)),
      ({ coverImage }) => (isValidCoverImage(coverImage) ? 1 : 2)
    );

/**
 * Preferred in order: language then published date.
 * Choose an alternative cover image if necessary, in the same language
 *
 * TODO: Think about parts, series...
 * TODO: Unit test
 */
export const useRepresentativeManifestation = ({
  representativeLanguageCode,
  representativeFormat,
  ...work
}: WorkToBeRepresented): DecoratedManifestation | undefined => {
  const { user } = useRediaPlatformContext();
  const { isilCodeFromBranchCode } = useLibrarySystemBranches();

  const preferredIsilCode = isilCodeFromBranchCode && user ? isilCodeFromBranchCode(user.pickupBranchCode) : undefined;
  const languageCodeOrder = createLanguageCodeOrder(representativeLanguageCode);
  const formatOrder = representativeFormat ? unique([representativeFormat, ...defaultFormatOrder]) : defaultFormatOrder;

  const sortManifestations = sortManifestationsByRelevance<DecoratedManifestation>(
    work.title,
    languageCodeOrder,
    formatOrder,
    preferredIsilCode
  );

  const manifestationsWithLanguages = getDecoratedWorkManifestations(work);
  const sortedManifestations = sortManifestations(manifestationsWithLanguages);
  const representativeManifestation = sortedManifestations?.[0];

  if (!representativeManifestation) return undefined;

  // TODO: Handle several languages. URL parameters with several?
  const representativeLanguage = representativeManifestation.languages[0]?.code;

  const mostRelevantCoverImage = sortedManifestations
    .filter(({ languages }) => !!languages[0] && languages[0].code === representativeLanguage)
    // TODO: Also force same format?
    .find(({ coverImage }) => isValidCoverImage(coverImage))?.coverImage;

  return { ...representativeManifestation, coverImage: mostRelevantCoverImage };
};

export const filterManifestationsOnHoldings = <ManifestationType extends Partial<Manifestation>>(
  manifestations: ManifestationType[],
  libraryNumbers: string[] | null
): ManifestationType[] =>
  manifestations
    .filter(({ holdings = [] }) => !!holdings?.some(isHoldingRepresentedInIsilNumbers(libraryNumbers)))
    .map((manifestation) => ({
      ...manifestation,
      holdings: manifestation.holdings?.filter(isHoldingRepresentedInIsilNumbers(libraryNumbers)),
    }));

export const getFormattedRepresentativeManifestationTitle = (
  work: Work,
  representativeManifestation: Manifestation | undefined
) => {
  const title = representativeManifestation?.title ?? work.title;
  return isbdFormattedTitle(title, havePartTitles(work.title));
};

export const getRelevantManifestations = (work: WorkToBeRepresented) => {
  const manifestationsWithLanguages = getDecoratedWorkManifestations(work);

  return manifestationsWithLanguages.filter(
    ({ languages, documentType }) =>
      !!languages.find(({ code }) => code === work.representativeLanguageCode) &&
      documentType?.format === work.representativeFormat
  );
};
