import { ChangeEventHandler, ComponentProps, FormEventHandler, useCallback, useEffect, useState } from "react";
import { useUrlQueryParameters } from "../../../utils/hooks/useUrlQueryParameters";
import { searchParameterName } from "../../searchApi";
import { emptySearchResults } from "../../types";
import { useSearchContext } from "./SearchContext";
import { SearchInput } from "./SearchInput";

type SearchViewInputProps = Omit<ComponentProps<typeof SearchInput>, "onSubmit" | "value" | "onChange" | "clear">;

export const SearchViewInput = (props: SearchViewInputProps) => {
  const { loading, updateSearchResults } = useSearchContext();
  const [inputValue, setInputValue] = useState("");

  const {
    parameterValues: [searchQuery],
    updateParameters,
  } = useUrlQueryParameters([searchParameterName]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  const onSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      updateParameters([{ key: searchParameterName, value: inputValue }]);
    },
    [inputValue, updateParameters]
  );

  const clear = () => {
    setInputValue("");
    updateParameters([{ key: searchParameterName, value: "" }]);
    updateSearchResults(emptySearchResults);
  };

  // Update input value when mounting page with a defined searchQuery (i.e. .../sok?s=<searchQuery>)
  useEffect(() => {
    if (loading && searchQuery && !inputValue) setInputValue(searchQuery);
  }, [loading, inputValue, searchQuery]);

  return (
    <SearchInput
      value={inputValue}
      onChange={onChange}
      onSubmit={onSubmit}
      loading={loading}
      clear={clear}
      {...props}
    />
  );
};
