import { Holdings } from "../client/interfaces";

export const mockHoldings: Record<string, Holdings | undefined> = {
  "650344": [
    {
      locations: [
        {
          items: [
            {
              availableState: "notAvailable",
              itemId: "ix:650344,1",
              loanableState: "loanable",
              reservableId: "ix:650344,1",
            },
          ],
          location: {
            branchName: "Tønsberg",
            path: [
              {
                name: "Tønsberg Voksen",
              },
            ],
          },
          shelfmark: "CDM2 S",
        },
      ],
      numberOfReservations: 0,
      periodical: null,
      publicationId: "650344",
      reservableId: "650344",
    },
  ],
};
