import { Patron } from "../client/interfaces";

export const mockPatron: Patron = {
  patronId: "ix912",
  firstName: "Test",
  surname: "Testesen",
  email: "test@example.com",
  pickupBranchCode: "tøb",
  phoneNumbers: [
    {
      default: true,
      number: "93846261",
      type: "mobile",
    },
  ],
  identificationCards: [
    {
      type: "no_local",
      identifier: "N12345678",
    },
  ],
};
