import mergeWith from "lodash/mergeWith";
import uniqWith from "lodash/uniqWith";

type ItemsEqual = (leftItem: unknown, rightItem: unknown) => boolean;

const mergeFunction = (itemsEqual?: ItemsEqual) => (leftItem: unknown, rightItem: unknown) => {
  if (!Array.isArray(leftItem) || !Array.isArray(rightItem)) return;

  if (typeof itemsEqual === "function") return uniqWith([...leftItem, ...rightItem], itemsEqual);

  return [...leftItem, ...rightItem];
};

/**
 * Merge objects in such a way that arrays are concatenated, optionally making sure the elements are unique.
 */
export const mergeObjectsConcatenatingArrays = <T extends unknown>(
  itemsEqual: ItemsEqual | undefined,
  ...objects: Partial<T>[]
): T => mergeWith({}, ...objects, mergeFunction(itemsEqual));

const hasType = (item: unknown): item is { type: string } =>
  typeof item === "object" && typeof item?.["type"] === "string";

const hasId = (item: unknown): item is { id: string } => typeof item === "object" && typeof item?.["id"] === "string";

export const equalByTypeAndId = (leftItem: unknown, rightItem: unknown) =>
  hasType(leftItem) &&
  hasType(rightItem) &&
  leftItem.type === rightItem.type &&
  hasId(leftItem) &&
  hasId(rightItem) &&
  leftItem.id === rightItem.id;
