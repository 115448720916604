import { Holdings, PhysicalItem } from "@biblioteksentralen/cordata";
import { LocalizedField } from "@libry-content/localization";

const useRestrictionKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"];

type UseRestrictionKey = (typeof useRestrictionKeys)[number];

const isUseRestrictionKey = (item: unknown): item is UseRestrictionKey =>
  typeof item === "string" && (useRestrictionKeys as string[]).includes(item);

/**
 * Restrictions in data summarized by 04.10.23:
 *
 * 1:	  82184
 * 6:	  700
 * 9:	  463491
 * 12:	62367
 * 13:	7441
 */

export const translatedUseRestrictions: Record<UseRestrictionKey, Required<LocalizedField<string>>> = {
  "0": { nb: "Ubestemt", nn: "Ubestemt" },
  "1": { nb: "Ikke til utlån", nn: "Ikkje til utlån" },
  "2": { nb: "Kun til bruk i biblioteket", nn: "Berre til bruk i biblioteket" },
  "3": { nb: "Kun endagslån", nn: "Berre eindagslån" },
  "4": { nb: "Kun til bruk i kontrollerte omgivelser", nn: "Berre til bruk i kontrollerte omgivnader" },
  "5": { nb: "Lån kan ikke fornyes", nn: "Lån kan ikkje fornyast" },
  "6": { nb: "Hurtiglån", nn: "Hurtiglån" },
  "7": { nb: "Lån begrenset på brukertype, vanlig låneperiode", nn: "Lån avgrensa på brukartype, vanleg låneperiode" },
  "8": { nb: "Lån begrenset på brukertype, lang låneperiode", nn: "Lån avgrensa på brukartype, lang låneperiode" },
  "9": { nb: "Terminlån", nn: "Terminlån" },
  "10": { nb: "Semesterlån", nn: "Semesterlån" },
  "11": { nb: "Tilgjengelig uten retur", nn: "Tilgjengeleg utan retur" },
  "12": { nb: "Ikke til fjernlån", nn: "Ikkje til fjernlån" },
  "13": { nb: "Kan ikke fjernlånes uten godkjenning", nn: "Kan ikkje fjernlånast utan godkjenning" },
  "14": { nb: "Skjør, håndter forsiktig", nn: "Skjør, handter varsamt" },
};

// TODO: Confirm these
const useRestrictionsNotAvailableForReservation: UseRestrictionKey[] = ["6", "7", "8"];

export const itemIsAvailable = ({ circulationStatus }: Pick<PhysicalItem, "circulationStatus">) =>
  circulationStatus === "0";

export const holdingIsAvailable = ({ items = [] }: Pick<Holdings, "items">) => items.some(itemIsAvailable);

export const itemCanBeReserved = ({ useRestrictions }: Pick<PhysicalItem, "useRestrictions">) =>
  !isUseRestrictionKey(useRestrictions) || !useRestrictionsNotAvailableForReservation.includes(useRestrictions);

export const holdingCanBeReserved = ({ items = [] }: Pick<Holdings, "items">) => items.some(itemCanBeReserved);

export const itemIsQuickLoan = ({ useRestrictions }: Pick<PhysicalItem, "useRestrictions">) => useRestrictions === "6";

export const isilCodeToLibraryNumber = (isilCode: string) => isilCode.replace(/^NO-/, "");

export const libraryNumberToIsilCode = (libraryNumber: string) => `NO-${libraryNumber}`;

export const isHoldingRepresentedInIsilNumbers =
  (libraryNumbers: string[] | null) =>
  ({ isilCode }: Holdings) =>
    !!libraryNumbers?.includes(isilCodeToLibraryNumber(isilCode));
