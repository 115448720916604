import { PatronReservation } from "../client/interfaces";
import { mockISOdateString } from "./ISOdateString";

export const mockReservations: PatronReservation[] = [
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:636813,103",
    itemId: "636813",
    pickupBranchCode: "tøb",
    pickupExpireDate: mockISOdateString("date", 1),
    pickupId: "3/   5",
    pickupState: "ready",
    publicationId: "636813",
    queueNumber: 1,
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:724561,178",
    itemId: "724561",
    pickupBranchCode: "hbhv",
    pickupExpireDate: mockISOdateString("date", 4),
    pickupId: "9/   6",
    pickupState: "ready",
    publicationId: "724561",
    queueNumber: 62,
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:469219,176",
    itemId: "469219",
    pickupBranchCode: "tbh",
    pickupExpireDate: mockISOdateString("date", 5),
    pickupId: "8/   6",
    pickupState: "ready",
    publicationId: "469219",
    queueNumber: 40,
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:477106,106",
    itemId: "477106",
    pickupBranchCode: "tbh",
    pickupExpireDate: mockISOdateString("date", 10),
    pickupId: "8/   7",
    pickupState: "ready",
    publicationId: "477106",
    queueNumber: 4,
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:476731,122",
    itemId: "476731",
    pickupBranchCode: "tbh",
    pickupExpireDate: mockISOdateString("date", 10),
    pickupId: "4/   6",
    pickupState: "ready",
    publicationId: "476731",
    queueNumber: 19,
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:476958,110",
    itemId: "476958",
    pickupBranchCode: "tbh",
    pickupExpireDate: mockISOdateString("date", 10),
    pickupId: "",
    // @ts-ignore
    pickupState: "",
    publicationId: "476958",
    queueNumber: 4,
  },
];

export const mockReservation: PatronReservation = {
  createdTime: mockISOdateString("datetime", -10),
  id: "ix:636813,103",
  itemId: "636813",
  pickupBranchCode: "tøb",
  pickupExpireDate: mockISOdateString("date", 1),
  pickupId: "",
  pickupState: "ready",
  publicationId: "636813",
  queueNumber: 1,
};
