import { isDevelopment } from "@biblioteksentralen/js-utils";
import { isJestTest } from "@libry-content/common";
import {
  createRediaPlatformClient,
  createRediaPlatformMockClient,
  Patron,
  RediaPlatform,
} from "@libry-content/redia-platform";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import { z } from "zod";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { useClientSideReady } from "../utils/hooks/useClientSideReady";

export interface RediaPlatformContextType {
  isRediaPlatformConfigured: boolean;
  isSessionReady: boolean;
  rediaPlatform: RediaPlatform | undefined;
  user: Patron | undefined;
  toggleUseMockClient: () => void;
}

const initialState: RediaPlatformContextType = {
  rediaPlatform: undefined,
  user: undefined,
  isSessionReady: false,
  isRediaPlatformConfigured: false,
  toggleUseMockClient: () => null,
};

export const RediaPlatformContext = createContext(initialState);

export const useRediaPlatformContext = () => useContext(RediaPlatformContext);

const rediaPlatformConfigSchema = z.object({
  NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT: z.enum(["dev", "staging", "prod"]),
  NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID: z.string(),
});

const rediaPlatformConfig = rediaPlatformConfigSchema.safeParse({
  NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT: process.env.NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT,
  NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID: process.env.NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID,
});

if (!rediaPlatformConfig.success && !isJestTest) {
  console.warn(`Redia Platform integration not enabled`, rediaPlatformConfig.error);
}

export function RediaPlatformProvider({ children }: { children: ReactNode }) {
  const [useMockClient, toggleUseMockClient] = useToggle(isDevelopment());
  const customerId = useCommonData()?.site?.librarySystem?.rediaCustomerId;
  const clientSideReady = useClientSideReady();
  const [user, setUser] = useState<Patron | undefined>(undefined);
  const [isSessionReady, setIsSessionReady] = useState<boolean>(false);
  const isRediaPlatformConfigured = rediaPlatformConfig.success && typeof customerId !== "undefined";

  const rediaPlatform = useMemo(() => {
    if (!clientSideReady || !rediaPlatformConfig.success || typeof customerId === "undefined") return undefined;

    const clientConstructor = useMockClient ? createRediaPlatformMockClient : createRediaPlatformClient;
    return clientConstructor({
      environment: rediaPlatformConfig.data.NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT,
      clientId: rediaPlatformConfig.data.NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID,
      customerId,
      onSessionChange: (session) => {
        // Oppdaterer React state når bruker har logget inn/ut
        console.debug("User state changed", session?.user);
        setUser(session?.user);
        setIsSessionReady(true);
      },
      onSessionExpired: () => {
        // Kanskje vi burde vise en form for toast her? Eller noe annet?
        // Vi trenger ikke kalle setUser her fordi onSessionChange også kalles.
      },
    });
  }, [clientSideReady, customerId, useMockClient]);

  useEffect(() => {
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === rediaPlatform?.sessionStorageKey) {
        // Oppdaterer React state når bruker har logget inn/ut i annet vindu/annen fane.
        setUser(rediaPlatform?.getUser());
      }
    };
    window.addEventListener("storage", onStorageEvent);
    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, [rediaPlatform]);

  return (
    <RediaPlatformContext.Provider
      value={{
        rediaPlatform,
        user,
        isSessionReady,
        isRediaPlatformConfigured,
        toggleUseMockClient,
      }}
    >
      {children}
    </RediaPlatformContext.Provider>
  );
}
